import React from 'react'
import { graphql } from 'gatsby'
import { pluckMetaValueFromShopifyProduct, getShopifyGatsbyImageData } from '../utils'
import { Home } from '../components/pages'
import { ShopItemType, ShopifyVariant, ShopifyMetafield } from '../types'

const Index: React.FC<Props> = ({ data, location }) => {
  const vinyl = data?.vinyl?.nodes || []
  const merch = data?.merch?.nodes || []
  const news = data?.news?.nodes[0] || {}

  const parsedVinyl: ShopItemType[] = vinyl
    .filter((item) => getShopifyGatsbyImageData(item))
    .map((vinylItem) => {
      return {
        slug: vinylItem.handle,
        productType: vinylItem.productType,
        title: pluckMetaValueFromShopifyProduct(vinylItem, 'artists'),
        subtitle: pluckMetaValueFromShopifyProduct(vinylItem, 'title'),
        gatsbyImageData: getShopifyGatsbyImageData(vinylItem),
        metafields: vinylItem.metafields,
        variants: vinylItem.variants
      }
    })

  const parsedMerch: ShopItemType[] = merch
    .filter((item) => getShopifyGatsbyImageData(item))
    .map((merchItem) => {
      return {
        slug: merchItem.handle,
        productType: merchItem.productType,
        title: merchItem.title,
        gatsbyImageData: getShopifyGatsbyImageData(merchItem),
        variants: merchItem.variants
      }
    })

  const parsedPrint: ShopItemType[] = merch
    .filter((item) => getShopifyGatsbyImageData(item))
    .map((merchItem) => {
      return {
        slug: merchItem.handle,
        productType: merchItem.productType,
        title: merchItem.title,
        gatsbyImageData: getShopifyGatsbyImageData(merchItem),
        variants: merchItem.variants
      }
    })

  const featureImage = {
    hoverText: `News: ${news.title}`,
    gatsbyImageData: news.image.gatsbyImageData,
    link: 'news'
  }

  return (
    <Home
      records={parsedVinyl}
      merch={parsedMerch}
      print={parsedPrint}
      featureImage={featureImage}
      location={location}
    />
  )
}

export default Index

export const query = graphql`
  query HomePageQuery {
    merch: allShopifyProduct(
      filter: { productType: { eq: "Apparel" } }
      sort: { fields: createdAt, order: DESC }
      limit: 8
    ) {
      nodes {
        title
        handle
        productType
        variants {
          shopifyId
          price
          title
          compareAtPrice
        }
        images {
          gatsbyImageData(layout: CONSTRAINED, width: 512)
        }
      }
    }
    vinyl: allShopifyProduct(
      filter: { productType: { eq: "BSR Releases" } }
      sort: { fields: createdAt, order: DESC }
      limit: 8
    ) {
      nodes {
        handle
        title
        productType
        variants {
          shopifyId
          price
          title
          compareAtPrice
        }
        images {
          gatsbyImageData(layout: CONSTRAINED, width: 512)
        }
        metafields {
          namespace
          key
          value
        }
      }
    }
    print: allShopifyProduct(
      filter: { productType: { eq: "Print" } }
      sort: { fields: createdAt, order: DESC }
      limit: 8
    ) {
      nodes {
        handle
        title
        productType
        variants {
          shopifyId
          price
          title
          compareAtPrice
        }
        images {
          gatsbyImageData(layout: CONSTRAINED, width: 512)
        }
        metafields {
          namespace
          key
          value
        }
      }
    }
    news: allContentfulNews(sort: { fields: createdAt, order: DESC }, limit: 1) {
      nodes {
        title
        image {
          gatsbyImageData(layout: CONSTRAINED, height: 1440)
        }
      }
    }
  }
`

interface Props {
  data: {
    merch: {
      nodes: MerchNode[]
    }
    vinyl: {
      nodes: VinylNode[]
    }
    news: {
      nodes: NewsNode[]
    }
  }
  location: any
}

interface NewsNode {
  title: string
  image: {
    gatsbyImageData: any
  }
}

interface VinylNode {
  title: string
  handle: string
  productType: string
  variants: ShopifyVariant[]
  metafields: ShopifyMetafield[]
  images: ShopifyImageNode[]
}

interface MerchNode {
  title: string
  handle: string
  productType: string
  variants: ShopifyVariant[]
  images: ShopifyImageNode[]
}

interface ShopifyImageNode {
  gatsbyImageData: any
}
